<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="线索来源">
              <a-input placeholder="请输入" v-model="mainSource" allow-clear @pressEnter="initSearchData('search')" />
            </a-form-item>
          </a-col>
        
          <a-col :span="6">
            <a-form-model-item label="线索阶段">
              <a-select
                placeholder="请选择"
             
                allowClear
                 v-model="stage"
              >
                <a-select-option :value="item.value" v-for="(item, index) in clueStatusArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        
          <a-col :md="6" :sm="24" style="float: right; text-align: right">
            <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
              <a-button type="primary" style="margin-right: 10px" @click="initSearchData">查询</a-button>
              <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-wrap">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="tableData"
        @change="handleTableChange"
        :pagination="initPagination"
        :scroll="{ y: 600 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <span slot="stuNumber" slot-scope="code, record">
          {{ `${record.name}（${code}）` }}
        </span>
       <span slot="fullName" slot-scope="code, record" style="cursor:pointer;color:#04CB94">
          <span v-if="record.recommendStuId===null">
            -
          </span>
          <spam v-else  @click="hrefStudent(record)">
              {{ `${record.recommendStuName}（${record.recommendStuId}）` }}
          </spam>
        
        </span>
        <a slot="level" slot-scope="text">{{ text }}</a>
   <span slot="studentCode" slot-scope="studentCode, record">
        <a @click="toStudentDetail(record)">{{ `${record.studentName}(${studentCode})` }}</a>
      </span>
        <template slot="userName" slot-scope="userName, record">
          <span style="font-weight: 600; font-size: 16px">{{ userName }}</span>
          <p>{{ record.role }} ({{ record.department }})</p>
        </template>
       <template slot="resellerName" slot-scope="resellerName, record">
        <span v-if="record.resellerCode===null">
          -
        </span>
         <span v-else>{{ `${resellerName}(${record.resellerCode})` }}</span>
         
        </template>
        <template slot="productTags" slot-scope="productTags, record">
          <span v-for="i in record.productTags" :key="i">
            {{
              initTabsList(i, tabsList).length !== 0 && ['CHINESE', 'MATH'].find((item) => i == item)
                ? initTabsList(i, tabsList)[0].label
                : ' '
            }}
          </span>
        </template>
        <template slot="orderStatus" slot-scope="orderStatus">
          <span> {{ initTabsList(orderStatus, orderStatusArray)[0].label }} </span>
        </template>
        <template slot="approveStatus" slot-scope="approveStatus">
          <span> {{ initTabsList(approveStatus, approvalStatus)[0].label }} </span>
        </template>
        <template slot="paymentMethod" slot-scope="paymentMethod">
          <span> {{ initTabsList(paymentMethod,payMethodArr).length>0?initTabsList(paymentMethod,payMethodArr)[0].label:'' }} </span>
        </template>
        <template slot="logisticsStatus" slot-scope="logisticsStatus">
          <span> {{ initTabsList(logisticsStatus, deliveryStatus)[0].label }} </span>
        </template>
        <template slot="money" slot-scope="money">
          <span>{{ money.currency }} {{ money.value.toFixed(2) }}</span>
        </template>

        <template slot="operation" slot-scope="operation, record">
          <span class="operation" @click="lookDetail(record)">详情</span>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';

const session = storage.createStore(sessionStorage);
import { searchMyClue,queryStudentId} from '@/api/headTeacher';
import { getCRMAddress } from '@/utils/util';

export default {
  name: 'corderIndex',
  data() {
    return {
      getCRMAddress,
      trackIds:[],
      mainSource:undefined,
      stage:undefined,
      clueStatusArr:[
        {label:'待分配',value:'TRACK_ASSIGNING'},
        {label:'待跟进',value:'TRACK_TO_FOLLOW'},
        {label:'跟进中',value:'TRACK_FOLLOWING'},
        {label:'已约课',value:'TRACK_FINISHED'},
        {label:'已约课待跟进',value:'CC_TO_FOLLOW'},
        {label:'已约课跟进中',value:'CC_FOLLOWING'},
        {label:'发送订单',value:'CC_ORDER_SENT'},
        {label:'财务确认到账',value:'CC_ORDER_AFFIRMED'},
        {label:'已交接',value:'CC_FINISHED'},
      ],
      paymentMethod:undefined,
       deliveryStatus: [
        { label: '无需发货', value: 'NO_NEED_TO_SHIP' },
        { label: '待确认', value: 'PENDING_CONFIRM' },
        { label: '待发货', value: 'PENDING' },
        { label: '已发货', value: 'SENT' },
        { label: '已取消', value: 'CANCEL' },
        { label: '打包中', value: 'WAITING' },
        { label: '已签收', value: 'FINISH' },
      ],
      payMethodArr:[
          { label: 'Latipay', value: 'LATIPAY' },
          { label: 'Airwallex', value: 'AIRWALLEX' },
         { label: 'Paypal', value: 'PAYPAL' },
          { label: 'Klarna', value: 'KLARNA' },
         { label: 'Stripe', value: 'STRIPE' },
          { label: '转账', value: 'REMITTANCE' },
         { label: '支付宝', value: 'ALIPAY' },
          { label: '微信', value: 'WECHAT' },
           { label: '信用卡', value: 'CARD' },
         { label: '银联', value: 'UPOP' },
          { label: 'ATOME', value: 'ATOME' },
          { label: '零元', value: 'ZERO' },
          { label: '其它', value: 'OTHER' },
      ],
      approvalStatus: [
        { label: '审核中', value: 'PENDING' },
        { label: '已通过', value: 'PASSED' },
        { label: '已拒绝', value: 'REJECTED' },
       { label: '已撤销', value: 'CANCEL' },
      ],
      tabsList: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '新生报班', value: 'NEW_STU_REPORT_CLASS' },
        { label: '续费', value: 'RENEW' },
        { label: '补升', value: 'MAKE_UP' },
        { label: '赠课时', value: 'HANDSEL_COURSE' },
      ],
      orderStatusArray: [
        { label: '待支付', value: 'PENDING_PAYMENT' },
        { label: '已支付', value: 'PAYMENT_RECEIVED' },
        { label: '已支付待确认', value: 'CONFIRMED' },
        { label: '已取消', value: 'CANCELLED' },

        // { label: '已退款', value: '已退款' },
      ],
      pageNum: 1,
      tableSorter: {},
      tags: [],
      studentCode: undefined,
      orderNumber: undefined,
      orderStatusSet: [],
      deptId: undefined,
      customerId: undefined,
      whenCreatedFrom: null,
      whenCreatedTo: null,
      queryClassDateArr: [],
      creatorList: [],
      treeData: [],
      orderStatusArr: [
        { label: '待支付', value: 'PENDING_PAYMENT' },
        { label: '已支付', value: 'PAYMENT_RECEIVED' },
        { label: '已支付待确认', value: 'CONFIRMED' },
        { label: '已取消', value: 'CANCELLED' },
      ],
      orderTypeArr: [
        { label: '学生续费', value: '续费' },
        { label: '学生补升', value: '补升' },
        { label: '定金', value: '定金' },
        { label: '新生报班', value: '新生报班' },
        { label: '国际运费', value: '国际运费' },
        { label: '学员升级', value: '学员升级' },
        { label: '活动寄送', value: '活动寄送' },
       { label: '竞赛代报名', value: '竞赛代报名' }
      ],
  columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,

        },
        {
          title: '线索名称',
          dataIndex: 'name',
          key: 'name',
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#04CB94',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                 session.set('clueDetail',record)
                    this.$router.push({path:'/clueDetail1'})
                },
              },
            };
          },
        },
     
        {
          title: '线索来源',
          dataIndex: 'mainSource',
          key: 'mainSource',
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
        },
        {
          title: '学员',
          dataIndex: 'stuNumber',
          key: 'stuNumber',
           scopedSlots: { customRender: 'stuNumber' },
        },
        {
          title: '推荐人',
          dataIndex: 'recommendStuName',
          key: 'recommendStuName',
        scopedSlots: { customRender: 'fullName' },
        },
       {
          title: '分销大使',
          dataIndex: 'resellerName',
          key: 'resellerName',
          scopedSlots: { customRender: 'resellerName' },
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          key: 'email',
        },

        {
          title: '线索类型',
          dataIndex: 'type',
          key: 'type',
        },
      

        {
          title: '阶段',
          dataIndex: 'stage',
          key: 'stage',
        },
      
        {
          title: '科目',
          dataIndex: 'subject',
          key: 'subject',
          width:80
        },
         {
          title: '学生课节状态',
          dataIndex: 'courseResult',
          key: 'courseResult',
       
        },
          {
          title: '试听时间(北京)',
          dataIndex: 'courseStartTime',
          key: 'courseStartTime',
        
        },
         {
          title: '课节取消类别',
          dataIndex: 'category',
          key: 'category',
        
        },
        // {
        //   title: '未试听原因',
        //   dataIndex: 'notClassReason',
        //   key: 'notClassReason',
        //   width: 200,
        //   ellipsis: true,
        // },
       
        {
          title: '创建时间',
          dataIndex: 'whenCreated',
          key: 'whenCreated',
          sorter:true
        },
      
      ],
    
      tableData: [],
      current: 0,
      pageSize: 10,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },

      loading: false,
    };
  },
  methods: {
    moment,
    queryStudentId(code){
      let params={
          code:code
      }
      queryStudentId(params).then(res=>{
          let uuid=res.data.content
            const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: { uuid:uuid },
      });
      window.open(routeUrl.href, '_blank');
      })
    },
      toStudentDetail(record) {
         this.$router.push({
                    path: '/studentDetail',
                    query: {
                      uuid: record.studentId,
                    },
                 });
    },
    lookDetail(data) {
      this.$router.push({
        path: '/orderIndex/orderDetail',
        query: {
          orderId: data.uuid,
          approveStatus: data.approveStatus,
          orderStatus: data.orderStatus,
          logisticsStatus: data.logisticsStatus,
          studentId:data.studentId
        },
      });
    },
    initTabsList(value, data) {
        const arr =data.filter((i) => i.value === value)
        return arr.length?arr:[{}];
    },
    
  
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },

    hrefStudent(record) {
      //去查学生ID
      let code=record.recommendStuId

     this.queryStudentId(code)
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },

    onClose() {
      this.visible = false;
    },

    handleTableChange(data, filters, sorter) {
      this.tableSorter = sorter;
      this.pageSize = data.pageSize;
      this.pageNum = data.current;
      this.initPagination.current = data.current;

      this.initSearchData();
    },

    resetCondition() {
      this.orderNumber = undefined;
      this.studentCode = undefined;
      this.deptId = undefined;
      this.customerId = undefined;
      this.orderStatusSet = [];
      this.queryClassDateArr=[];
      this.whenCreatedFrom = null;
      this.whenCreatedTo = null;
      this.paymentMethod=undefined;
      this.mainSource=undefined;
      this.stage=undefined;
      this.tags = [];
      this.pageNum = 1;
      this.pageSize = 10;
      this.initSearchData('search')
    },
    initSearchData(act) {
      this.loading = true;
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        trackIds:this.trackIds,
        stage:this.stage,
        mainSource:this.mainSource,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      
      };
  console.log(params)
   
      let sort = '';
      if (this.tableSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tableSorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.tableSorter.order) {
        params.orderBy = `${this.tableSorter.columnKey} ${sort}`;
      }

      searchMyClue(params).then((res) => {
        this.tableData = res.data.content;
        this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
        this.initPagination.total = res.data.totalCount;
        this.initPagination.pageSize = res.data.pageSize;
        this.loading = false;
      });
    },
  },
  created() {
   
  },
  mounted() {
  if(this.$route.query.trackIds){
    this.trackIds=JSON.parse(this.$route.query.trackIds)
    console.log(this.trackIds)
   }
    this.initSearchData();
  },
};
</script>

<style lang="less" scoped>
.table-wrap {
  padding: 20px;
  background: #fff;
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.operation {
  color: #3372fe;
  cursor: pointer;
}
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.table-wrap {
  padding: 20px;
  background: #fff;
}
.sel-navwrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.task-info {
  .task-type {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    span {
      padding: 2px 10px;
      background-color: #f7f7f7;
      border-radius: 6px;
      margin-right: 10px;
    }
  }
  .task-form {
    border-bottom: 1px solid #eee;
    .form-name {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 2px 10px;
        border-radius: 6px;
        margin: 0 10px;
      }
    }
    .pending-status {
      color: #ffbf00;
      background-color: rgba(255, 191, 0, 0.09);
    }
    .success-status {
      color: #00cf64;
      background-color: rgba(0, 207, 100, 0.09);
    }
    .refuse-status {
      color: #ff5353;
      background-color: rgba(255, 83, 83, 0.09);
    }
    .form-teacher {
      font-size: 16px;
      font-weight: 400;
      color: #999;
      line-height: 22px;
      margin-bottom: 20px;
      .dueDate {
        color: #ff5454;
      }
      span {
        color: #333;
      }
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .form-des {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .form-imgs {
      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-right: 10px;
      }
      margin-bottom: 20px;
    }
  }
  .courseInfo {
    padding: 20px 0;
    .course-info {
      padding: 20px 16px;
      background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
      border-radius: 20px;
      .course-name {
        font-size: 16px;
        font-weight: 600;
        color: #009cff;
        line-height: 22px;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
      .course-type {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-top: 10px;
      }
    }
  }
  .taskFormBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 700px;
    border-top: 1px solid #e9e9e9;
    background-color: #fff;
    padding: 10px 16px;
    background: '#fff';
    text-align: right;
    z-index: 1;
    img {
      margin: 0 10px 0 0;
    }
    .ant-btn-primary {
      // background-color: #009cff;
    }
  }
}
.apply-item {
  margin-bottom: 10px;
  display: flex;
}
.apply-label {
  color: rgba(31, 45, 61, 0.4);
  min-width: 43px;
}
.apply-content {
  color: #1f2d3d;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;

  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.line-wrap {
  margin-top: 20px;

  border-bottom: 1px solid #eee;
}
.apply-detail {
  margin-top: 20px;
}
.examine-item {
  margin-bottom: 14px;
  display: flex;
}
.examine-label {
  width: 80px;
}
.examine-content {
  flex: 1;
}
.trans-wrap {
  background: rgba(0, 0, 0, 0.02);
  height: 280px;
  display: flex;
}
.trans-itemWrap {
  flex: 1;
  padding: 10px 20px;
}
.trans-item {
  margin-top: 5px;
}
.trans-itemLabel {
}
</style>
